var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-1/6" },
    [
      _c("v-select", {
        attrs: { items: _vm.locales, "prepend-icon": "translate", dense: "" },
        model: {
          value: _vm.locale,
          callback: function($$v) {
            _vm.locale = $$v
          },
          expression: "locale"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }