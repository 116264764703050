import { render, staticRenderFns } from "./SessionTable.vue?vue&type=template&id=20fabadd&"
import script from "./SessionTable.vue?vue&type=script&lang=js&"
export * from "./SessionTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VCardTitle,VProgressLinear,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/Users/musili/musilidev/webroot/work-bmm/01_DEX/dex/front-end/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20fabadd')) {
      api.createRecord('20fabadd', component.options)
    } else {
      api.reload('20fabadd', component.options)
    }
    module.hot.accept("./SessionTable.vue?vue&type=template&id=20fabadd&", function () {
      api.rerender('20fabadd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/tenant_customer/views/Sessions/components/SessionTable.vue"
export default component.exports