import { render, staticRenderFns } from "./Password.vue?vue&type=template&id=5b179519&"
import script from "./Password.vue?vue&type=script&lang=js&"
export * from "./Password.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VForm,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/Users/musili/musilidev/webroot/work-bmm/01_DEX/dex/front-end/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b179519')) {
      api.createRecord('5b179519', component.options)
    } else {
      api.reload('5b179519', component.options)
    }
    module.hot.accept("./Password.vue?vue&type=template&id=5b179519&", function () {
      api.rerender('5b179519', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/tenant_customer/views/Settings/Password.vue"
export default component.exports