<template>
  <div class="ma-4" app>
    <v-container fluid class="full-height">
      <v-row>
        <v-col md="6">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div
              class="px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
            >
              <h3 class="text-lg font-medium text-gray-900">
                {{ $t("customer.page.dashboard.products") }}
              </h3>
            </div>
            <div class="pb-5 space-y-6 bg-white">
              <ProductsTable />
            </div>
            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
              <router-link
                to="subscriptions"
                class="font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600"
              >
                {{ $t("customer.page.dashboard.seemoreproducts") }}
              </router-link>
            </div>
          </div>
        </v-col>
        <v-col md="6">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div
              class="px-4 py-5 space-y-6 bg-white border-b border-gray-200 sm:p-6"
            >
              <h3 class="text-lg font-medium text-gray-900">
                {{ $t("customer.page.dashboard.uploads") }}
              </h3>
            </div>
            <div class="pb-5 space-y-6 bg-white">
              <SessionsTable />
            </div>
            <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
              <router-link
                to="subscriptions"
                class="font-semibold text-teal-500 transition duration-150 ease-in-out hover:text-teal-600"
              >
                {{ $t("customer.page.dashboard.seemoresessions") }}
              </router-link>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="flex center-center">
        <v-col md="6" class="justify-center">
          <div class="shadow sm:rounded-md sm:overflow-hidden"></div>
        </v-col>
        <v-col md="6" class="justify-center">
          <div class="shadow sm:rounded-md sm:overflow-hidden"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProductsTable from "./Components/ProductsTable";
import SessionsTable from "./Components/SessionsTable";

export default {
  components: {
    ProductsTable,
    SessionsTable,
  },
  mounted() {
    this.$store.dispatch("getDashboardDataTenantCustomer");
  },
};
</script>

<style></style>
