import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=abf7f9aa&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/Users/musili/musilidev/webroot/work-bmm/01_DEX/dex/front-end/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('abf7f9aa')) {
      api.createRecord('abf7f9aa', component.options)
    } else {
      api.reload('abf7f9aa', component.options)
    }
    module.hot.accept("./Dashboard.vue?vue&type=template&id=abf7f9aa&", function () {
      api.rerender('abf7f9aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/tenant_customer/views/Dashboard/Dashboard.vue"
export default component.exports