var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.success
      ? _c(
          "p",
          {
            staticClass:
              "max-w-xl p-4 mx-auto text-2xl text-green-500 bg-green-100 border border-green-500 rounded"
          },
          [_vm._v(" " + _vm._s(_vm.$t("common.page.password.success")) + " ")]
        )
      : _c(
          "section",
          { staticClass: "max-w-xl p-3 mx-auto mt-2 bg-white rounded" },
          [
            _c("h2", { staticClass: "my-3 text-xl" }, [
              _vm._v(_vm._s(_vm.$t("common.page.password.title")))
            ]),
            _c(
              "v-form",
              { ref: "form" },
              [
                _c("v-text-field", {
                  attrs: {
                    label: _vm.$t("common.page.password.currentpassword"),
                    type: "password",
                    rules: _vm.passwordRules,
                    error: _vm.errorMessage ? true : false,
                    "error-messages": _vm.errorMessage,
                    outlined: ""
                  },
                  model: {
                    value: _vm.oldPassword,
                    callback: function($$v) {
                      _vm.oldPassword = $$v
                    },
                    expression: "oldPassword"
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    label: _vm.$t("common.page.password.newpassword"),
                    hint: _vm.$t("common.page.password.hint"),
                    type: "password",
                    rules: _vm.passwordRules,
                    outlined: ""
                  },
                  model: {
                    value: _vm.newPassword,
                    callback: function($$v) {
                      _vm.newPassword = $$v
                    },
                    expression: "newPassword"
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    label: _vm.$t("common.page.password.confirmpassword"),
                    hint: _vm.$t("common.page.password.hint"),
                    type: "password",
                    rules: _vm.passwordRules.concat([
                      _vm.newPassword === _vm.repeatNewPassword ||
                        _vm.$t("common.page.password.confirmerror")
                    ]),
                    outlined: ""
                  },
                  model: {
                    value: _vm.repeatNewPassword,
                    callback: function($$v) {
                      _vm.repeatNewPassword = $$v
                    },
                    expression: "repeatNewPassword"
                  }
                }),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "blue darken-1", outlined: "", small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.changePassword($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("common.page.password.title")) + " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }