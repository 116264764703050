<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <h2>{{ $t("customer.page.session.title") }}</h2>
      </v-card-title>
      <v-card-text>
        <div v-if="!isUploadComplete">
          <p class="my-3 text-2xl">
            {{ $t("customer.page.session.progress") }}
          </p>
          <v-progress-linear
            indeterminate
            rounded
            color="blue darken-4"
          ></v-progress-linear>
        </div>

        <v-simple-table flat v-if="isUploadComplete">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("customer.page.session.headers.productname") }}
                </th>
                <th class="text-left">
                  {{ $t("customer.page.session.headers.row") }}
                </th>
                <th class="text-left">
                  {{ $t("customer.page.session.headers.rowerrors") }}
                </th>
                <th class="text-left">
                  {{ $t("customer.page.session.headers.downloaded") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ session.product_name }}</td>
                <td>{{ $n(session.total_document_count) }}</td>
                <td>{{ $n(session.failed_validation_document_count) }}</td>
                <td>{{ session.downloaded }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import firebase from "firebase/app";

export default {
  data() {
    return {
      session: {},
      poller: null,
    };
  },
  mounted() {
    const session_id = this.$route.params.id;
    this.session = this.getSessions.get(session_id);
    if (!this.isUploadComplete) {
      this.poller = setInterval(() => {
        this.getSession();
      }, 3000);
    }
  },
  beforeDestroy() {
    clearInterval(this.poller);
    console.log(`Poller removed: ${this.poller}`);
  },
  methods: {
    getSession() {
      const session_id = this.session.session_id;
      const subscription_id = this.session.bmm_dex_subscription_id;
      const tenant_id = this.getTenantID;
      const user = firebase.auth().currentUser;

      let url = `${process.env.VUE_APP_API_URL}/get_single_session`;
      url = url + "?tenant=" + tenant_id;
      url = url + "&session=" + session_id;
      console.log(`DEBUG: SessionTable.vue - url => ${url}`);

      user
        .getIdToken(true)
        .then(async (token) => {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json",
          };

          axios
            .get(url, { headers: headers })
            .then((response) => {
              const data = {
                subscription_id: subscription_id,
                data: [response.data],
              };
              this.$store.commit("setSubscriptionSessions", data);
              this.session = response.data;
              console.log(data);
            })
            .catch((error) => {
              console.log(error.response);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapGetters(["getLoadingSessionData", "getSessions", "getTenantID"]),
    isUploadComplete: function () {
      return (
        this.session &&
        (this.session.bmm_dex_state == "complete" ||
          this.session.bmm_dex_state == "failed_validation")
      );
    },
  },
  watch: {
    isUploadComplete(newValue) {
      if (newValue) {
        clearInterval(this.poller);
        console.log(`Poller removed: ${this.poller}`);
      }
    },
  },
};
</script>

<style></style>
