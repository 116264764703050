<template>
  <div class="ma-4" app>
    <v-container fluid class="full-height">
      <v-row>
        <v-col md="12">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div
              class="flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
            >
              <h3 class="text-lg font-medium text-gray-900">
                {{ $t("customer.page.subscription.title") }}
              </h3>
              <v-spacer></v-spacer>
            </div>
            <ol class="py-2 space-y-3 list-decimal list-inside bg-white">
              <li
                v-for="(sub, subIndex) in getDistributorSubs.values()"
                :key="subIndex"
              >
                <router-link
                  :to="{
                    name: 'SubscriptionDetails',
                    params: { id: sub.product_id },
                  }"
                >
                  {{ sub.product_name }}
                </router-link>
              </li>
            </ol>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "Home",
  mounted() {
    this.$store.dispatch("getCustomerSubscriptions");
  },
  computed: {
    ...mapGetters(["getLoadingDistributorSubs", "getDistributorSubs"]),
  },
};
</script>
