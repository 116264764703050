<template>
  <div class="ma-4" app>
    <h1 class="text-2xl">Api keys</h1>
    <span class="block p-2 my-4 bg-white border rounded">{{
      getApiKey || "No api key created"
    }}</span>

    <h2 class="mb-4 text-xl">List of API's</h2>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 class="text-lg">{{ apis[0].title }}</h3>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Endpoint</h4>
          <span class="px-4 py-2 bg-white">{{ apis[0].url }}</span>

          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Headers</h4>

          <div class="overflow-hidden border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Required
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(header, headerIndex) in apis[0].headers"
                  v-bind:key="headerIndex"
                >
                  <td class="px-6 py-4 whitespace-nowrap">{{ header.name }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.required }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Request params</h4>

          <div class="overflow-hidden border-b border-gray-200">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Required
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase "
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="(header, headerIndex) in apis[0].requestParams"
                  v-bind:key="headerIndex"
                >
                  <td class="px-6 py-4 whitespace-nowrap">{{ header.name }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.required }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ header.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4 class="px-2 my-2 mt-5 text-lg font-medium">
            Sample Request Body
          </h4>
          <pre class="font-mono border-b">
                        {{ JSON.stringify(apis[0].request, undefined, 2) }}
                    </pre
          >

          <h4 class="px-2 my-2 mt-5 text-lg font-medium">Sample Response</h4>
          <pre class="font-mono">
                        {{ JSON.stringify(apis[0].response, undefined, 2) }}
                    </pre
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      apis: [
        {
          url: `${process.env.VUE_APP_API_URL}/api_customer`,
          title: "POST  a single session",
          headers: [
            {
              name: "Authorization",
              required: "Yes",
              description: "Contains the API key. Written as 'Basic API_KEY'",
            },
          ],
          requestParams: [
            {
              name: "session_id",
              required: "Yes",
              description: "Id of session whose data is requested",
            },
          ],
          request: {
            bmm_dex_product_id: "yecZyb89poPWUSTGjRLT12xp",
            bmm_dex_subscription_id: "XaE912XQOMSh89p9n5893MGd",
            bmm_dex_customer_id: "dFvb13GqXdqOqa2I9C67QwKf",
            bmm_dex_event_type: "enrolment",
            documents: [
              {
                origin_uid: 2,
                name: "Kahwi Leonard",
                scope_id: 5,
              },
              {
                origin_uid: 5,
                name: "Nikola Jokic",
                scope_id: 12,
              },
              {
                origin_uid: 6,
                name: "Dennis Mitchel",
                scope_id: 13,
              },
            ],
          },
          response: { bmm_dex_session_id: "yifv34CgCC7Mv9OrLB678XRdp" },
        },
        {
          url: `${process.env.VUE_APP_API_URL}/api_session_data`,
          title: "Get a test data",
          headers: [
            {
              name: "Authorization",
              required: "Yes",
              description: "Contains the authentication key",
            },
          ],
          requestParams: [
            {
              name: "session_id",
              required: "Yes",
              description: "Id of session whose data is requested",
            },
          ],
          response: {
            bmm_dex_submitted_via: "api_submission",
            failed_validation_document_count: 1,
            total_document_count: 3,
            bmm_dex_event_type: "enrolment",
            bmm_dex_created_at: "2021-03-08 11:53:43",
            bmm_dex_state: "failed_validation",
            session_documents: [
              {
                bmm_dex_created_at: "2021-03-08 11:53:43",
                origin_uid: 2,
                name: "Kahwi Leonard",
                scope_id: 5,
              },
              {
                origin_uid: 6,
                bmm_dex_created_at: "2021-03-08 11:53:43",
                name: "Dennis Mitchel",
                scope_id: 13,
              },
              {
                bmm_dex_created_at: "2021-03-08 11:53:43",
                scope_id: 12,
                origin_uid: 5,
                name: "Nikola Jokic",
              },
            ],
          },
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("fetchApiKey");
  },
  computed: { ...mapGetters(["getApiKey"]) },
};
</script>