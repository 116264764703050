import Vue from "vue";
import DistributorApp from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "../../plugins/vuetify";
import i18n from '../../plugins/i18n';

let distributor_app;
distributor_app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(DistributorApp)
}).$mount("#app");

export default distributor_app;
