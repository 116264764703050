var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c("h1", { staticClass: "text-2xl" }, [_vm._v("Api keys")]),
      _c("span", { staticClass: "block p-2 my-4 bg-white border rounded" }, [
        _vm._v(_vm._s(_vm.getApiKey || "No api key created"))
      ]),
      _c("h2", { staticClass: "mb-4 text-xl" }, [_vm._v("List of API's")]),
      _c(
        "v-expansion-panels",
        { attrs: { accordion: "" } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("h3", { staticClass: "text-lg" }, [
                  _vm._v(_vm._s(_vm.apis[0].title))
                ])
              ]),
              _c("v-expansion-panel-content", [
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Endpoint")]
                ),
                _c("span", { staticClass: "px-4 py-2 bg-white" }, [
                  _vm._v(_vm._s(_vm.apis[0].url))
                ]),
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Headers")]
                ),
                _c(
                  "div",
                  { staticClass: "overflow-hidden border-b border-gray-200" },
                  [
                    _c(
                      "table",
                      { staticClass: "min-w-full divide-y divide-gray-200" },
                      [
                        _c("thead", { staticClass: "bg-gray-50" }, [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Name ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Required ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Description ")]
                            )
                          ])
                        ]),
                        _c(
                          "tbody",
                          { staticClass: "bg-white divide-y divide-gray-200" },
                          _vm._l(_vm.apis[0].headers, function(
                            header,
                            headerIndex
                          ) {
                            return _c("tr", { key: headerIndex }, [
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(_vm._s(header.name))]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.required) + " ")]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.description) + " ")]
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Request params")]
                ),
                _c(
                  "div",
                  { staticClass: "overflow-hidden border-b border-gray-200" },
                  [
                    _c(
                      "table",
                      { staticClass: "min-w-full divide-y divide-gray-200" },
                      [
                        _c("thead", { staticClass: "bg-gray-50" }, [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Name ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Required ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ",
                                attrs: { scope: "col" }
                              },
                              [_vm._v(" Description ")]
                            )
                          ])
                        ]),
                        _c(
                          "tbody",
                          { staticClass: "bg-white divide-y divide-gray-200" },
                          _vm._l(_vm.apis[0].requestParams, function(
                            header,
                            headerIndex
                          ) {
                            return _c("tr", { key: headerIndex }, [
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(_vm._s(header.name))]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.required) + " ")]
                              ),
                              _c(
                                "td",
                                { staticClass: "px-6 py-4 whitespace-nowrap" },
                                [_vm._v(" " + _vm._s(header.description) + " ")]
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v(" Sample Request Body ")]
                ),
                _c("pre", { staticClass: "font-mono border-b" }, [
                  _vm._v(
                    "                      " +
                      _vm._s(
                        JSON.stringify(_vm.apis[0].request, undefined, 2)
                      ) +
                      "\n                  "
                  )
                ]),
                _c(
                  "h4",
                  { staticClass: "px-2 my-2 mt-5 text-lg font-medium" },
                  [_vm._v("Sample Response")]
                ),
                _c("pre", { staticClass: "font-mono" }, [
                  _vm._v(
                    "                      " +
                      _vm._s(
                        JSON.stringify(_vm.apis[0].response, undefined, 2)
                      ) +
                      "\n                  "
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }