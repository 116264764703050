import firebase from "firebase/app";
import axios from "axios";

export default {
  state: {
    sessions: [],
    dashBoardData: {}
  },
  actions: {
    async getInsurerSessions({ commit }) {
      console.log("Getting Sessions");
      var all_sessions = [];
      var db = firebase.firestore();
      var hosturl = window.location.host;
      var tenant = hosturl.split(".")[0];
      var insurer_doc = db.collection("Insurers").doc(tenant.toUpperCase());
      var insurer_sessions = await insurer_doc
        .collection("enrolment-sessions")
        .get();
      console.log("The Sessions");
      console.log(insurer_sessions.docs);
      insurer_sessions.docs.forEach(session_doc => {
        all_sessions.push(session_doc);
      });
      commit("setInsurerSession", all_sessions);
    },
    getDashboardDataTenantCustomer({ commit }) {
      // commit("setLoadingProducts", true);
      console.log("getDashboardDataTenant");
      const user = firebase.auth().currentUser;
      console.log(user);
      let url = `${process.env.VUE_APP_API_URL}/dashboard_data_tenant_customer`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };

          const tenantId = user.tenantId;
          const uid = user.uid;
          console.log(`DEBUG: tenant_customer/dashboard/store ..tenant_id => ${tenantId}`)
          var hosturl = window.location.host;
          url = url + "?tenant_id=" + tenantId;
          url = url + "&tag=" + uid;

          console.log(`DEBUG: dashboard/store ..URL => ${url}`)

          const response = await axios.get(url, {
            headers: headers
          });

          // console.log(`DEBUG: AFTER AXIOS..tenant_customer/products/store ..URL => ${url}`)
          console.log(response.data);
          commit("setDashboardData", response.data);
        })
        .catch(error => {
          console.log(error);
          commit("setDashboardData", {});
        });
    }
  },
  mutations: {
    setInsurerSession(state, payload) {
      state.sessions = payload;
    },
    setDashboardData(state, payload) {
      state.dashBoardData = payload;
    },
  },
  getters: {
    getInsurerSession: state => state.sessions,
    getDashboardData: state => state.dashBoardData
  }
};
