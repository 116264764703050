<template>
  <div class="">
    <div class="px-4 py-5 sm:overflow-hidden">
      <h3 class="my-3 text-lg font-medium text-gray-900">
        {{ $t("customer.page.subscriptionsessions.title") }}
      </h3>
      <v-data-table
        :headers="subscriptionHeaders"
        :loading="getLoadingSubSessions"
        :loading-text="$t('customer.page.subscriptionsessions.progress')"
        :items="sessions"
        :items-per-page="30"
      >
        <template v-slot:item.bmm_dex_created_at="{ item }">
          {{ $d(utcToDate(item.bmm_dex_created_at), "long") }}
        </template>
        <template v-slot:item.total_document_count="{ item }">
          {{ $n(item.total_document_count) }}
        </template>
        <template v-slot:item.failed_validation_document_count="{ item }">
          {{ $n(item.failed_validation_document_count) }}
        </template>
        <template v-slot:item.data="{ item }">
          <router-link
            :to="{ name: 'Session', params: { id: item.session_id } }"
          >
            {{ $t("customer.page.subscriptionsessions.headers.data") }}
          </router-link>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "subscription_products",
  data() {
    return {
      sessions: [],
    };
  },
  mounted() {
    const subscription_id = this.$route.params.subscription_id;
    this.$store.dispatch("getSubscriptionSessions", subscription_id);
    this.subSessions();
  },
  methods: {
    subSessions() {
      const session = [];
      const subscription_id = this.$route.params.subscription_id;
      console.log(`Subscription id ${subscription_id}`);
      if (this.getSubSessions.has(subscription_id)) {
        this.getSubSessions.get(subscription_id).forEach((sub_id) => {
          session.push(this.getSessions.get(sub_id));
        });
      }
      this.sessions = session;
    },
    utcToDate(datetimeStr) {
      const datetime = datetimeStr.split(" ");
      const date = datetime[0];
      const time = datetime[1];
      const dateArray = date.split("-").map((str) => parseInt(str));
      const timeArray = time.split(":").map((str) => parseInt(str));
      return new Date(
        Date.UTC(
          dateArray[0],
          dateArray[1],
          dateArray[2],
          timeArray[0],
          timeArray[1],
          timeArray[2]
        )
      );
    },
  },
  computed: {
    ...mapGetters(["getLoadingSubSessions", "getSubSessions", "getSessions"]),
    subscriptionHeaders() {
      return [
        {
          text: this.$t(
            "customer.page.subscriptionsessions.headers.productname"
          ),
          value: "product_name",
        },
        {
          text: this.$t("customer.page.subscriptionsessions.headers.created"),
          value: "bmm_dex_created_at",
        },
        {
          text: this.$t("customer.page.subscriptionsessions.headers.row"),
          value: "total_document_count",
        },
        {
          text: this.$t("customer.page.subscriptionsessions.headers.rowerrors"),
          value: "failed_validation_document_count",
          sortable: true,
        },
        {
          text: this.$t("customer.page.subscriptionsessions.headers.data"),
          value: "data",
        },
      ];
    },
  },
  watch: {
    getLoadingSubSessions(newValue) {
      if (!newValue) {
        this.subSessions();
      }
    },
  },
};
</script>