<template>
  <nav>
    <v-app-bar flat app class="grey lighten-3">
      <v-app-bar-nav-icon
        @click="toggleDrawer"
        class="grey--text"
        v-if="getCurrentUser != null"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <!-- <span class="font-weight-light">BMM</span>
        <span>DEX</span> -->
        <span>
          <img
            class="w-auto h-8 mr-3 sm:h-10"
            src="../../../assets/images/logo-17.png"
            alt="dex-logo"
          />
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="flex justify-end space-x-6">
        <LocaleSwitcher />
        <v-btn
          color="secondary"
          outlined
          v-if="getCurrentUser != null"
          @click="signOut"
        >
          <span>{{ $t("common.signout") }}</span>
          <v-icon right>exit_to_app</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer :value="getDrawerStatus" app dark class="primary">
      <v-list class="pt-16">
        <div v-for="(link, i) in links" :key="i">
          <v-list-item v-if="!link.sublinks" :key="i" router :to="link.route">
            <v-list-item-icon>
              <v-icon v-text="link.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :value="false"
            :prepend-icon="link.icon"
            color="white"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(sublink, i) in link.sublinks"
              :key="i"
              :to="sublink.route"
            >
              <div class="flex pl-4">
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="sublink.text"></v-list-item-title>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <template v-slot:append>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>account_circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="getCurrentUser.displayName"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="getCurrentUser.email"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>apartment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                getCustomerDetails
                  ? getCustomerDetails.name
                  : $t("common.unknown")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import firebase from "firebase/app";
import { mapGetters } from "vuex";
import LocaleSwitcher from "./LocaleSwitcher";

export default {
  components: { LocaleSwitcher },
  data() {
    return {
      getCurrentUser: firebase.auth().currentUser,
    };
  },
  computed: {
    ...mapGetters(["getDrawerStatus", "getCustomerDetails"]),
    links() {
      return [
        {
          icon: "home",
          text: this.$t("navbar.dashboard"),
          route: "/",
        },
        {
          icon: "person",
          text: this.$t("navbar.users"),
          route: "/users",
        },
        {
          icon: "folder",
          text: this.$t("navbar.products"),
          route: "/subscriptions",
        },
        {
          icon: "storage",
          text: this.$t("navbar.data"),
          route: "/data",
        },
        {
          icon: "assignment_ind",
          text: this.$t("navbar.uploaddata"),
          route: "/upload",
        },
        {
          icon: "settings",
          text: this.$t("navbar.settings"),
          sublinks: [
            {
              icon: "language",
              text: this.$t("navbar.api"),
              route: "/api",
            },
            {
              icon: "account_circle",
              text: this.$t("navbar.account"),
              route: "/settings/password",
            },
          ],
        },
      ];
    },
  },
  methods: {
    async signOut() {
      try {
        this.$store.commit("setCurrentUser", null);
        const baseUrl = window.location.origin;
        window.location.assign(baseUrl);
        await firebase.auth().signOut();
      } catch (error) {
        console.log(error);
      }
    },
    toggleDrawer() {
      this.$store.commit("setDrawerStatus", !this.$store.state.drawerStatus);
    },
  },
};
</script>
