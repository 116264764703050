import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Users from "../views/Users/Users.vue";
import NewUser from "../views/Users/NewUser.vue";
import Upload from "../views/Upload/Upload.vue";
import Subscriptions from "../views/Subscriptions/Subscriptions.vue";
import Data from "../views/Data/Data.vue";
import SubscriptionSessions from "../views/Data/SubscriptionSessions.vue";
import SessionsData from "../views/Sessions/Sessions.vue";
import Api from "../views/Api/Api.vue";
import SubscriptionDetails from "../views/Subscriptions/SubscriptionDetails";
import Password from "../views/Settings/Password.vue"

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/users",
    name: "Users",
    component: Users
  },
  {
    path: "/users/new",
    name: "NewUser",
    component: NewUser
  },
  {
    path: "/upload",
    name: "Upload",
    component: Upload
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    component: Subscriptions
  },
  {
    path: "/subscriptions/:id",
    name: "SubscriptionDetails",
    component: SubscriptionDetails
  },
  {
    path: "/data",
    name: "Data",
    component: Data
  },
  {
    path: "/data/:subscription_id",
    name: "SubscriptionSessions",
    component: SubscriptionSessions
  },
  {
    path: "/session/:id",
    name: "Session",
    component: SessionsData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/api",
    name: "Api",
    component: Api,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings/password",
    name: "Password",
    component: Password,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
