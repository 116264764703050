<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
        >
          {{ $t("customer.page.dashboard.productstable.name") }}
        </th>
      </tr>
    </thead>
    <tbody class="bg-white divide-y divide-gray-200" x-max="1">
      <tr v-for="item in getDashboardData['subscriptions']" :key="item.id">
        <td class="px-6 py-4 whitespace-nowrap">
          <div class="flex items-center">
            <div class="">
              <div class="text-sm font-medium text-gray-900">
                {{ item.product_name }}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters(["getDashboardData"]),
  },
};
</script>

<style></style>
