<template>
  <div class="ma-4" app>
    <v-container fluid class="full-height">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6">
          <h3 class="text-lg font-medium text-gray-900">
            {{ $t("common.page.users.users") }}
          </h3>
          <v-spacer></v-spacer>
          <router-link
            to="/users/new"
            v-if="isAdmin"
            class="px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50"
          >
            {{ $t("common.page.users.createuser") }}
          </router-link>
        </div>
        <div class="pb-5 space-y-6 bg-white">
          <v-data-table
            :loading="getloadingDistUsers"
            :loading-text="$t('common.page.users.loadingtext')"
            :headers="headers"
            :items="getdistUsers"
            :items-per-page="30"
            flat
          >
            <template v-slot:item.actions="{ item }">
              <div v-if="isAdmin">
                <v-icon small class="mr-2" @click="openDialog(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  small
                  @click="deleteUser(item)"
                  v-if="!isCurrentUserData(item) && isAdmin"
                  >mdi-delete</v-icon
                >

                <v-dialog
                  v-model="dialog"
                  max-width="800"
                  v-if="editing_user && editing_user.id == item.id"
                >
                  <div class="w-full p-2 bg-white">
                    <h4 class="mb-6 text-2xl">
                      {{ $t("common.page.users.updateuser") }}
                    </h4>
                    <v-form ref="userdata" lazy-validation>
                      <v-text-field
                        v-model="display_name"
                        :label="$t('common.name')"
                        :rules="nameRules"
                        outlined
                      ></v-text-field>

                      <v-radio-group
                        v-model="role"
                        class="ma-4"
                        v-if="!isCurrentUserData(item) && isAdmin"
                      >
                        <template v-slot:label>
                          <div class="text-lg text-gray-800">
                            {{ $t("common.page.users.role") }}
                          </div>
                        </template>
                        <v-radio
                          v-for="(description, role, index) in roles"
                          :key="index"
                          :label="description"
                          :value="role"
                        ></v-radio>
                      </v-radio-group>

                      <div class="flex justify-end">
                        <v-btn
                          color="red lighten-1"
                          text
                          @click.stop.prevent="dialog = false"
                        >
                          {{ $t("common.cancel") }}
                        </v-btn>

                        <v-btn
                          color="primary darken-1"
                          outlined
                          @click.stop.prevent="updateUserClaims"
                        >
                          {{ $t("common.update") }}
                        </v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-dialog>
              </div>
            </template>
            <template v-slot:item.role="{ item }">
              <span v-if="item.role != null">{{ userRole(item) }}</span>
              <span v-else>No role</span>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { getUserRole } from "../../../../utils/roles.js";
import { mapGetters } from "vuex";

export default {
  name: "Users",
  data() {
    return {
      dialog: false,
      editing_user: null,
      display_name: "",
      role: "Admin",
    };
  },
  mounted() {
    console.log("dispatching users get users");
    this.$store.dispatch("getDistributorUsers");
  },
  methods: {
    openDialog(user) {
      this.dialog = true;
      this.editing_user = user;
      this.role = getUserRole(user);
      this.display_name = user.display_name;
    },
    showDialogue() {
      this.$store.commit("setUserDialog", true);
    },
    deleteUser(user) {
      var data = {
        email: user.email,
      };
      this.$store.dispatch("deleteDistributorUser", data);
    },
    updateUserClaims() {
      const isFormValid = this.$refs.userdata.validate();
      if (isFormValid) {
        const data = {};
        data.display_name = this.display_name;
        data.uid = this.editing_user.id;

        if (!this.isCurrentUserData(this.editing_user) && this.isAdmin) {
          data.role = this.role;
        }
        this.$store.dispatch("updateDistributorUserClaims", data);
        this.dialog = false;
      }
    },
    userRole(user) {
      return getUserRole(user);
    },
  },
  computed: {
    ...mapGetters([
      "getdistUsers",
      "getloadingDistUsers",
      "isCurrentUserData",
      "isAdmin",
    ]),
    headers() {
      return [
        {
          text: this.$t("common.name"),
          value: "display_name",
          align: "start",
        },
        { text: this.$t("common.email"), value: "email" },
        { text: this.$t("common.page.users.role"), value: "role" },
        {
          text: this.$t("common.page.users.actions"),
          value: "actions",
          sortable: false,
        },
      ];
    },
    roles() {
      return {
        admin: this.$t("common.page.users.roles.admin"),
        editor: this.$t("common.page.users.roles.editor"),
        viewer: this.$t("common.page.users.roles.viewer"),
      };
    },
    nameRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("common.name") }),
        (v) =>
          v.length >= 3 ||
          this.$t("validation.string.length>=", {
            value: this.$t("common.name"),
            length: "3",
          }),
      ];
    },
  },
};
</script>
