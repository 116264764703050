<template>
  <v-app>
    <NavBar />
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/Navbar.vue";
export default {
  name: "DistributorApp",
  components: { NavBar },
  data: () => ({}),
  created() {
    this.$store.dispatch("authAction");
  },
  mounted() {
    // this.$store.dispatch("getTenantID");
    // this.$store.dispatch("authAction");
    // this.$router.replace({ name: "Home" });
    const path = "/"
    if (this.$route.path !== path) this.$router.push(path)
  }
};
</script>

<style></style>
