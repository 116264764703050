<template>
  <div class="ma-4" app>
    <v-container fluid class="full-height">
      <v-row>
        <v-col md="12">
          <div>
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div
                class="flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
              >
                <h3 class="text-lg font-medium text-gray-900">
                  {{ $t("customer.page.data.title") }}
                </h3>
                <v-spacer></v-spacer>
              </div>
              <v-data-table
                :headers="headers"
                :loading="getLoadingDistributorSubs"
                :loading-text="$t('customer.page.data.progress')"
                :items="Array.from(this.getDistributorSubs.values())"
              >
                <template v-slot:item.data="{ item }">
                  <router-link
                    :to="{
                      name: 'SubscriptionSessions',
                      params: { subscription_id: item.subscription_id },
                    }"
                  >
                    {{ $t("customer.page.data.view") }}
                  </router-link>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "products",
  mounted() {
    this.$store.dispatch("getCustomerSubscriptions");
  },
  computed: {
    ...mapGetters(["getLoadingDistributorSubs", "getDistributorSubs"]),
    headers() {
      return [
        {
          text: this.$t("customer.page.data.headers.productname"),
          value: "product_name",
        },
        { text: this.$t("customer.page.data.headers.sessions"), value: "data" },
      ];
    },
  },
};
</script>
