import firebase from "firebase/app";

export default {
    state: {
        apiKey: ""
    },
    mutations: {
        setApiKey(state, payload) {
            state.apiKey = payload;
        }
    },
    getters: {
        getApiKey: state => state.apiKey
    },
    actions: {
        async fetchApiKey({ commit, state, rootState }) {
            const tenantId = firebase.auth().tenantId; 
            const user_details = rootState.userDetails;
            const customerId = user_details.customer_id;
            
            if (tenantId && !state.apiKey) {
                const db = firebase.firestore();
                const tenant_doc = db.collection("tenants").doc(tenantId);
                const customer_doc = tenant_doc.collection("customers").doc(customerId)
                const info = await customer_doc.get();
                const data = info.data();
                console.log(data)
                commit("setApiKey", data.api_key);
            }
        }
    }
}