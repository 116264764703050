import axios from "axios";
import firebase from "firebase/app";
import router from "../../../router";

export default {
  state: {
    distributorUsers: [],
    loadingDistributorUsers: false,
    error: ""
  },
  actions: {
    async getDistributorUsers({ rootState, commit }) {
      commit("setLoadingDistributorUsers", true);
      const user = firebase.auth().currentUser;
      const url = `${process.env.VUE_APP_API_URL}/tenant_customer_users`;
      const user_details = rootState.userDetails;
      const customerId = user_details.customer_id;
      const tenantId = firebase.auth().tenantId;
      const gen_url = url + `?tenant_id=${tenantId}&customer_id=${customerId}`;

      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          const response = await axios.get(gen_url, { headers: headers });
          commit("setDistributorUsers", response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    async createDistUser({ rootState, commit }, payload) {
      commit("setLoadingDistributorUsers", true);
      var hosturl = window.location.host;
      var tenant = hosturl.split(".")[0];
      var tenant_id = rootState.tenantId;
      var user_details = rootState.userDetails;
      var distributorId = user_details.distributor_id;
      const user = rootState.currentUser;
      payload.tenant = tenant;
      payload.tenant_id = tenant_id;
      payload.distributor_id = distributorId;
      payload.customer_id = user_details.customer_id;
      console.log(payload);
      var url = `${process.env.VUE_APP_API_URL}/create_tenant_customer_user`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          console.log(url);
          axios.post(url, payload, { headers: headers })
            .then(response => {
              router.push({ path: "/users" });
            })
            .catch(error => {
              if (error.response) {
                commit("setDistUserError", error.response.data["Error"])
              }
              commit("setLoadingDistributorUsers", false);
            })
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateDistributorUserClaims({ dispatch, commit, rootState, rootGetters }, payload) {
      commit("setLoadingDistributorUsers", true);
      if (rootState.tenantId != "admin") {
        payload.tenant_id = rootState.tenantId;
      }
      const url = `${process.env.VUE_APP_API_URL}/tenant_customer_users`;
      const user = firebase.auth().currentUser;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json",
            Accept: "application/json"
          };
          const response = await axios.patch(url, payload, {
            headers: headers
          });
          if (response.status == "200") {
            dispatch("getDistributorUsers");
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteDistributorUser({ dispatch, commit, rootState, rootGetters }, payload) {
      if (rootGetters.isAdmin) {
        commit("setLoadingDistributorUsers", true);
        if (rootState.tenantId != "admin") {
          payload.tenant_id = rootState.tenantId;
        }
        console.log(payload);
        const url = `${process.env.VUE_APP_API_URL}/tenant_customer_users`;
        const user = firebase.auth().currentUser;
        user
          .getIdToken(true)
          .then(async function (token) {
            const headers = {
              Authorization: token.toString(),
              "Content-Type": "application/json",
              Accept: "application/json"
            };
            const response = await axios.delete(url, { headers: headers, data: payload });
            if (response.status == "200") {
              dispatch("getDistributorUsers");
            }
          })
          .catch(error => {
            console.log(error);
            commit("setLoadingDistributorUsers", false);
          });
      }
    },
  },
  mutations: {
    setLoadingDistributorUsers(state, payload) {
      state.loadingDistributorUsers = payload;
    },
    setDistributorUsers(state, payload) {
      console.log(payload);
      state.distributorUsers = payload;
      state.loadingDistributorUsers = false;
    },
    setDistUserError(state, payload) {
      state.error = payload;
    }
  },
  getters: {
    getloadingDistUsers: state => state.loadingDistributorUsers,
    getdistUsers: state => state.distributorUsers,
    getDistUserError: state => state.error
  }
};
