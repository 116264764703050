<template>
  <div class="w-1/6">
    <v-select
      v-model="locale"
      :items="locales"
      prepend-icon="translate"
      dense
    ></v-select>
  </div>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      locale: null,
    };
  },
  async mounted() {
    const token = await firebase.auth().currentUser.getIdTokenResult();

    console.log(token);
    if (token.claims.locale && this.locales.includes(token.claims.locale)) {
      const savedLocale = token.claims.locale;
      console.log(`Saved locale: ${savedLocale}`);
      if (this.locales.includes(savedLocale)) {
        this.$i18n.locale = savedLocale;
        console.log(`Set the locale to ${savedLocale}`);
      }
    }

    this.locale = this.$i18n.locale;
    console.log(this.locales);
  },
  watch: {
    locale: function (newLocale) {
      console.log(newLocale);
      if (this.$i18n.locale !== newLocale) {
        this.$i18n.locale = newLocale;
        const data = {
          locale: newLocale,
          uid: firebase.auth().currentUser.uid,
        };

        this.$store.dispatch("updateDistributorUserClaims", data);
      }
    },
  },
};
</script>