var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("v-card-title", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("customer.page.session.title")))])
          ]),
          _c(
            "v-card-text",
            [
              !_vm.isUploadComplete
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "my-3 text-2xl" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("customer.page.session.progress")) +
                            " "
                        )
                      ]),
                      _c("v-progress-linear", {
                        attrs: {
                          indeterminate: "",
                          rounded: "",
                          color: "blue darken-4"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isUploadComplete
                ? _c("v-simple-table", {
                    attrs: { flat: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "customer.page.session.headers.productname"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "customer.page.session.headers.row"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "customer.page.session.headers.rowerrors"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "customer.page.session.headers.downloaded"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.session.product_name))
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$n(_vm.session.total_document_count)
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$n(
                                          _vm.session
                                            .failed_validation_document_count
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.session.downloaded))
                                  ])
                                ])
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2838949611
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }