<template>
  <div>
    <p
      class="max-w-xl p-4 mx-auto text-2xl text-green-500 bg-green-100 border border-green-500 rounded"
      v-if="success"
    >
      {{ $t("common.page.password.success") }}
    </p>
    <section class="max-w-xl p-3 mx-auto mt-2 bg-white rounded" v-else>
      <h2 class="my-3 text-xl">{{ $t("common.page.password.title") }}</h2>

      <v-form ref="form">
        <v-text-field
          :label="$t('common.page.password.currentpassword')"
          v-model="oldPassword"
          type="password"
          :rules="passwordRules"
          :error="errorMessage ? true : false"
          :error-messages="errorMessage"
          outlined
        ></v-text-field>
        <v-text-field
          :label="$t('common.page.password.newpassword')"
          :hint="$t('common.page.password.hint')"
          v-model="newPassword"
          type="password"
          :rules="passwordRules"
          outlined
        ></v-text-field>
        <v-text-field
          :label="$t('common.page.password.confirmpassword')"
          :hint="$t('common.page.password.hint')"
          v-model="repeatNewPassword"
          type="password"
          :rules="[
            ...passwordRules,
            newPassword === repeatNewPassword ||
              $t('common.page.password.confirmerror'),
          ]"
          outlined
        ></v-text-field>

        <v-btn
          color="blue darken-1"
          outlined
          small
          @click.stop.prevent="changePassword"
        >
          {{ $t("common.page.password.title") }}
        </v-btn>
      </v-form>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Password",
  data: () => {
    return {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      errorMessage: "",
      success: false,
    };
  },
  computed: {
    passwordRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", {
            value: this.$t("common.password"),
          }),
        (v) =>
          (v && v.length >= 6) ||
          this.$t("validation.string.length>=", {
            value: this.$t("common.password"),
            length: "6",
          }),
      ];
    },
  },
  methods: {
    async changePassword() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        const user = firebase.auth().currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(
          user.email,
          this.oldPassword
        );
        try {
          await user.reauthenticateWithCredential(credentials);
          await user.updatePassword(this.newPassword);
          this.success = true;
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        } catch (error) {
          this.errorMessage = error.message;
        }
      }
    },
  },
};
</script>