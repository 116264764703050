<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <div class="sm:overflow-hidden">
          <h3 class="text-lg font-medium text-gray-900">
            {{ $t("customer.page.subscriptiondetails.title") }}
          </h3>

          <div class="p-5 space-y-6">
            <h2 class="text-lg font-bold">
              {{ this.getSubcriptionDetails.name }}
            </h2>

            <div>
              <h3 class="mb-4 text-base font-medium">
                {{ $t("customer.page.subscriptiondetails.events") }}
              </h3>
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(eventdetails, event, i) in this.getSubcriptionDetails
                    .events"
                  :key="i"
                >
                  <v-expansion-panel-header class="grey lighten-5">
                    <h4 class="text-base font-normal">{{ event }}</h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <h4 class="mt-4 text-sm font-bold">
                      {{ $t("customer.page.subscriptiondetails.fields") }}
                    </h4>

                    <v-expansion-panels class="mt-4 divide-y-2" accordion flat>
                      <v-expansion-panel
                        v-for="(fieldDetails, k) in visibleFields(eventdetails)"
                        :key="k"
                      >
                        <v-expansion-panel-header>
                          {{ fieldDetails.name }}
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <v-row>
                            <v-col cols="3">
                              <span>
                                {{
                                  $t(
                                    "customer.page.subscriptiondetails.required"
                                  )
                                }}
                                : {{ fieldDetails.nullable }}
                              </span>
                            </v-col>

                            <v-col cols="9">
                              <div
                                class="mb-3"
                                v-if="fieldDetails.text_file_config"
                              >
                                <h5>
                                  {{
                                    $t(
                                      "customer.page.subscriptiondetails.structuredfile"
                                    )
                                  }}
                                </h5>
                                <div class="justify-around sm:flex">
                                  <span
                                    >{{
                                      $t(
                                        "customer.page.subscriptiondetails.length"
                                      )
                                    }}:
                                    {{
                                      fieldDetails.text_file_config.length
                                    }}</span
                                  >
                                  <span
                                    >{{
                                      $t(
                                        "customer.page.subscriptiondetails.sequencenumber"
                                      )
                                    }}:
                                    {{
                                      fieldDetails.text_file_config
                                        .sequence_number
                                    }}</span
                                  >
                                </div>
                              </div>

                              <div
                                v-if="
                                  fieldDetails.validations &&
                                  fieldDetails.validations.length != 0
                                "
                              >
                                <h5>
                                  {{
                                    $t(
                                      "customer.page.subscriptiondetails.validations"
                                    )
                                  }}
                                </h5>
                                <v-data-table
                                  hide-default-footer
                                  :headers="validationHeaders"
                                  :items="fieldDetails.validations"
                                >
                                </v-data-table>
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    const product_id = this.$router.currentRoute.params.id;
    const host = window.location.host;
    this.$store.dispatch("getProductDetails", {
      product_id: product_id,
      host: host,
    });
  },
  computed: {
    ...mapGetters(["getSubcriptionDetails"]),
    validationHeaders() {
      return [
        {
          text: this.$t("customer.page.subscriptiondetails.headers.name"),
          value: "name",
        },
        {
          text: this.$t("customer.page.subscriptiondetails.headers.type"),
          value: "validation_type",
        },
      ];
    },
  },
  methods: {
    visibleFields(event) {
      return event.data_fields.filter(
        (field) => !field.name.startsWith("bmm_dex")
      );
    },
  },
};
</script>
