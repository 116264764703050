var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "px-4 py-5 sm:overflow-hidden" },
      [
        _c("h3", { staticClass: "my-3 text-lg font-medium text-gray-900" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("customer.page.subscriptionsessions.title")) +
              " "
          )
        ]),
        _c("v-data-table", {
          attrs: {
            headers: _vm.subscriptionHeaders,
            loading: _vm.getLoadingSubSessions,
            "loading-text": _vm.$t(
              "customer.page.subscriptionsessions.progress"
            ),
            items: _vm.sessions,
            "items-per-page": 30
          },
          scopedSlots: _vm._u([
            {
              key: "item.bmm_dex_created_at",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$d(_vm.utcToDate(item.bmm_dex_created_at), "long")
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.total_document_count",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(_vm.$n(item.total_document_count)) + " ")
                ]
              }
            },
            {
              key: "item.failed_validation_document_count",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$n(item.failed_validation_document_count)) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.data",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "Session", params: { id: item.session_id } }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "customer.page.subscriptionsessions.headers.data"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }