import axios from "axios";
import firebase from "firebase/app";

const db = firebase.firestore();

export default {
  state: {
    loadingSessionData: false,
    loadingSubscriptionSessions: false,
    loadingDistributorSubs: false,
    sessionData: [],
    sessions: new Map(),
    subscriptionSessions: new Map(),
    distributorSubs: new Map(),
    product_details: {},
  },
  mutations: {
    setSessionData(state, payload) {
      state.sessionData = payload;
      state.loadingSessionData = false;
    },
    setLoadingSessionData(state, payload) {
      state.loadingSessionData = payload;
    },
    setSubscriptionSessions(state, payload) {
      const subArray = state.subscriptionSessions.has(payload.subscription_id) ? state.subscriptionSessions.get(payload.subscription_id) : new Set();
      payload.data.forEach(session => {
        subArray.add(session.session_id);
        state.sessions.set(session.session_id, session);
      })
      state.subscriptionSessions.set(payload.subscription_id, subArray);
    },
    setLoadingSubscriptionSessions(state, payload) {
      state.loadingSubscriptionSessions = payload;
    },
    setCustomerSubscriptions(state, payload) {
      payload.forEach(subscription => state.distributorSubs.set(subscription.subscription_id, subscription))
    },
    setLoadingDistributorSubs(state, payload) {
      state.loadingDistributorSubs = payload;
    },
    setProductDetails(state, payload) {
      state.product_details = payload;
      state.product_details_loading = false;
    },
  },
  actions: {
    getSessionData({ commit, rootState, state }, payload) {
      console.log("Fetching Session Data");
      const user = rootState.currentUser;
      const tenantId = rootState.tenantId;
      var url = `${process.env.VUE_APP_API_URL}/get_session_events`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          var hosturl = window.location.host;
          var subdomain = hosturl.split(".")[0];
          url = url + "?subdomain=" + subdomain;
          url = url + "&tenant_id=" + tenantId;
          url = url + "&bmm_dex_session_id=" + payload.ref_id;
          url = url + "&event=" + payload.event;
          console.log(url);
          const response = await axios.get(url, {
            headers: headers
          });
          console.log(response.data);
          commit("setSessionData", response.data);
          console.log(state.sessions);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getSubscriptionSessions({ commit, rootState, state }, payload) {
      const subscription_id = payload;
      commit("setLoadingSubscriptionSessions", true);

      const user = rootState.currentUser;
      var url = `${process.env.VUE_APP_API_URL}/get_subscription_sessions`;
      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          var hosturl = window.location.host;
          var tenant = hosturl.split(".")[0];
          url = url + "?tenant=" + tenant;
          url = url + "&tenantId=" + rootState.tenantId;
          url = url + "&subscription_id=" + subscription_id;
          url = url + "&bmm_dex_subscription_id=" + subscription_id;
          console.log(url);
          const response = await axios.get(url, {
            headers: headers
          });
          const data = { subscription_id: subscription_id, data: response.data };
          commit("setSubscriptionSessions", data);
          commit("setLoadingSubscriptionSessions", false);
          console.log("DEBUG: get_subscription_sessions => " + data);
          console.log(data)
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingSubscriptionSessions", false);
        });
    },
    getCustomerSubscriptions({ commit, rootState, state }) {
      commit("setLoadingDistributorSubs", true);
      const user = rootState.currentUser;
      var url = `${process.env.VUE_APP_API_URL}/get_customer_subscriptions`;

      user
        .getIdToken(true)
        .then(async function (token) {
          const headers = {
            Authorization: token.toString(),
            "Content-Type": "application/json"
          };
          const tenantId = rootState.tenantId;
          const userDetails = rootState.userDetails;
          url = url + "?tenant_id=" + tenantId;
          url = url + "&customer_id=" + userDetails.customer_id;
          url = url + "&bmm_dex_customer_id=" + userDetails.customer_id;
          console.log(`DEBUG: Subscriptions/Store - getCustomerSubscriptions => ${url}`);
          const response = await axios.get(url, { headers: headers });
          console.log(`DEBUG: Subscriptions/Store - getCustomerSubscriptions ++ subscriptions => ${response.data[0]}`);
          console.log(response.data);
          commit("setCustomerSubscriptions", response.data);
          commit("setLoadingDistributorSubs", false);
        })
        .catch(error => {
          console.log(error);
          commit("setLoadingDistributorSubs", false);
        });
    },
    async getProductDetails({ commit }, payload) {
      commit("setProductDetails", {});
      console.clear();
      console.log("Fetching the Product details");
      console.log(payload)
      const product_id = payload.product_id;
      const hosturl = payload.host;
      const tenant = hosturl.split(".")[0];

      const matching_tenant_docs = await db.collection("tenants").where("subdomain", "==", tenant).get();

      const matching_docs_ids = [];

      matching_tenant_docs.forEach(doc => {
        matching_docs_ids.push(doc.id);
      })
      const tenant_doc_id = matching_docs_ids[0];

      console.log("DEBUG: tenant_doc_id => " + tenant_doc_id);
      const insurer_doc = db.collection("tenants").doc(tenant_doc_id);
      const product_ref = insurer_doc.collection("products").doc(product_id);
      const product = await product_ref.get();
      const product_data = product.data();
      commit("setProductDetails", product_data);
    },
  },
  getters: {
    getLoadingSessionData: state => state.loadingSessionData,
    getSessionData: state => state.sessionData,
    getSessions: state => state.sessions,
    getSubSessions: state => state.subscriptionSessions,
    getLoadingSubSessions: state => state.loadingSubscriptionSessions,
    getDistributorSubs: state => state.distributorSubs,
    getLoadingDistributorSubs: state => state.loadingDistributorSubs,
    getSubcriptionDetails: state => state.product_details
  }
};
