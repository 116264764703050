<template>
  <div class="max-w-xl px-2 py-4 mx-auto bg-white rounded shadow">
    <h2 class="my-4 text-xl">{{ $t("common.page.users.createuser") }}</h2>

    <v-form class="" ref="form" lazy-validation>
      <v-text-field
        :label="$t('common.name')"
        v-model="name"
        :rules="nameRules"
        required
        outlined
      ></v-text-field>

      <v-text-field
        :label="$t('common.email')"
        :error="this.getDistUserError ? true : false"
        :error-messages="this.getDistUserError"
        required
        outlined
        v-model="email"
        :rules="emailRules"
      ></v-text-field>

      <v-text-field
        :label="$t('common.password')"
        type="password"
        v-model="password"
        :rules="passwordRules"
        required
        outlined
      ></v-text-field>

      <v-radio-group
        v-model="role"
        :label="$t('common.page.users.role')"
        value="Viewer"
        mandatory
        row
      >
        <v-radio
          v-for="(description, role, index) in roles"
          :key="index"
          :label="description"
          :value="role"
        ></v-radio>
      </v-radio-group>

      <div class="flex justify-end space-x-3">
        <router-link to="/users">
          <v-btn color="grey darken-1" type="button" text small>{{
            $t("common.cancel")
          }}</v-btn>
        </router-link>
        <v-btn
          color="blue darken-1"
          type="submit"
          outlined
          small
          @click.stop.prevent="createUser"
        >
          {{ $t("common.page.users.createuser") }}
        </v-btn>
      </div>

      <v-progress-linear
        indeterminate
        color="blue"
        class="mt-2"
        v-if="getloadingDistUsers"
      ></v-progress-linear>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewUser",
  data: () => ({
    role: "",
    name: "",
    email: "",
    password: "",
  }),
  mounted() {
    this.$store.commit("setDistUserError", "");
    this.role = "";
    this.name = "";
    this.email = "";
    this.password = "";
  },
  methods: {
    createUser() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        const data = {
          display_name: this.name,
          email: this.email,
          password: this.password,
          role: this.role,
        };
        this.$store.dispatch("createDistUser", data);
      }
    },
  },
  computed: {
    ...mapGetters(["getDistUserError", "getloadingDistUsers"]),
    roles() {
      return {
        Viewer: this.$t("common.page.users.roles.viewer"),
        Admin: this.$t("common.page.users.roles.admin"),
        Editor: this.$t("common.page.users.roles.editor"),
      };
    },
    nameRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("common.name") }),
        (v) =>
          v.length >= 3 ||
          this.$t("validation.string.length>=", {
            value: this.$t("common.name"),
            length: "3",
          }),
      ];
    },
    emailRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("common.email") }),
        (v) => /.+@.+\..+/.test(v) || this.$t("validation.email.valid"),
      ];
    },
    passwordRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", {
            value: this.$t("common.password"),
          }),
        (v) =>
          (v && v.length >= 6) ||
          this.$t("validation.string.length>=", {
            value: this.$t("common.password"),
            length: "6",
          }),
      ];
    },
  },
};
</script>

<style></style>
