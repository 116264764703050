<template>
  <div>
    <div class="flex justify-center ma-5">
      <p class="p-4 bg-white rounded-md" v-if="isViewer">
        {{ $t("customer.page.upload.error") }}
      </p>
      <div class="w-2/4 shadow sm:rounded-md sm:overflow-hidden" v-else>
        <div class="flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6">
          <h3 class="text-lg font-medium text-gray-900">
            {{ $t("customer.page.upload.title") }}
          </h3>
        </div>

        <div class="p-5 space-y-6 bg-white">
          <v-select
            :items="subscriptions"
            :item-text="getItemText"
            :label="$t('customer.page.upload.product')"
            v-on:change="changeSelection"
          >
          </v-select>

          <v-select
            v-model="event_type"
            required
            :items="event_types"
            :label="$t('customer.page.upload.event')"
          >
          </v-select>

          <v-file-input
            :label="$t('customer.page.upload.file')"
            @change="DataChange"
          >
          </v-file-input>
        </div>

        <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
          <v-btn
            color="primary"
            outlined
            small
            @click="Upload"
            :disabled="dialog"
          >
            {{ $t("customer.page.upload.upload") }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("customer.page.upload.progress") }}
          <v-progress-linear indeterminate color="white" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import firebase from "firebase/app";

export default {
  data() {
    return {
      selected: {},
      file: {},
      event_type: "",
      event_types: [],
      data_direction: "to_tenant",
      bucket: "",
      subscription_id: "",
      dialog: false,
      subscriptions: [],
    };
  },
  methods: {
    DataChange(File) {
      this.file = File;
    },
    reset() {
      this.file = "";
      this.event_type = "";
      this.selected = "";
      this.dialog = false;
    },
    async Upload() {
      console.log(this.bucket);
      this.dialog = true;
      const customerId = this.$store.state.userDetails.customer_id;
      let user = firebase.auth().currentUser;
      let url = `${process.env.VUE_APP_API_URL}/upload_file`;
      var token = await user.getIdToken(true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("bmm_dex_event_type", this.event_type);
      formData.append("bmm_dex_data_direction", this.data_direction);
      formData.append("customer_id", customerId);
      formData.append("bmm_dex_customer_id", customerId);
      formData.append("bucket_name", this.bucket);
      let headers = {
        Authorization: token.toString(),
        "Content-Type": "multipart/form-data",
      };

      axios
        .post(url, formData, { headers: headers })
        .then((response) => {
          const session_id = response.data.bmm_dex_session_id;
          const session = {
            session_id: session_id,
            bucket_name: this.bucket,
            bmm_dex_state: "uploaded",
            bmm_dex_subscription_id: this.subscription_id,
            bmm_dex_customer_id: customerId,
            bmm_dex_session_id: session_id,
          };
          const data = {
            subscription_id: this.subscription_id,
            data: [session],
          };
          this.$store.commit("setSubscriptionSessions", data);
          console.log("Uploaded Successfully");
          this.reset();
          this.$router.push({ name: "Session", params: { id: session_id } });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          this.dialog = false;
        });
    },
    changeSelection(event) {
      var sub_name = event.trim();
      const subscriptions = Array.from(this.getDistributorSubs.values());
      var selected_sub_2 = subscriptions.findIndex(
        (x) => x.product_name == sub_name
      );
      this.subscription_id = subscriptions[selected_sub_2].subscription_id;
      this.bucket = subscriptions[selected_sub_2].bmm_dex_bucket_name;
      this.event_types = subscriptions[selected_sub_2].event_types;
    },
    getItemText(item) {
      return item.product_name;
    },
  },
  computed: {
    ...mapGetters([
      "getProducts",
      "getDistributorSubs",
      "isViewer",
      "getLoadingDistributorSubs",
    ]),
  },
  watch: {
    getLoadingDistributorSubs(newValue) {
      if (!newValue) {
        this.subscriptions = Array.from(this.getDistributorSubs.values());
      }
    },
  },
  mounted() {
    this.$store.dispatch("getCustomerSubscriptions");
    this.subscriptions = Array.from(this.getDistributorSubs.values());
  },
};
</script>

<style></style>
