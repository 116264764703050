var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "12" } }, [
            _c("div", { staticClass: "sm:overflow-hidden" }, [
              _c("h3", { staticClass: "text-lg font-medium text-gray-900" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("customer.page.subscriptiondetails.title")) +
                    " "
                )
              ]),
              _c("div", { staticClass: "p-5 space-y-6" }, [
                _c("h2", { staticClass: "text-lg font-bold" }, [
                  _vm._v(" " + _vm._s(this.getSubcriptionDetails.name) + " ")
                ]),
                _c(
                  "div",
                  [
                    _c("h3", { staticClass: "mb-4 text-base font-medium" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("customer.page.subscriptiondetails.events")
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "v-expansion-panels",
                      _vm._l(this.getSubcriptionDetails.events, function(
                        eventdetails,
                        event,
                        i
                      ) {
                        return _c(
                          "v-expansion-panel",
                          { key: i },
                          [
                            _c(
                              "v-expansion-panel-header",
                              { staticClass: "grey lighten-5" },
                              [
                                _c(
                                  "h4",
                                  { staticClass: "text-base font-normal" },
                                  [_vm._v(_vm._s(event))]
                                )
                              ]
                            ),
                            _c(
                              "v-expansion-panel-content",
                              [
                                _c(
                                  "h4",
                                  { staticClass: "mt-4 text-sm font-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "customer.page.subscriptiondetails.fields"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-expansion-panels",
                                  {
                                    staticClass: "mt-4 divide-y-2",
                                    attrs: { accordion: "", flat: "" }
                                  },
                                  _vm._l(
                                    _vm.visibleFields(eventdetails),
                                    function(fieldDetails, k) {
                                      return _c(
                                        "v-expansion-panel",
                                        { key: k },
                                        [
                                          _c("v-expansion-panel-header", [
                                            _vm._v(
                                              " " +
                                                _vm._s(fieldDetails.name) +
                                                " "
                                            )
                                          ]),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "customer.page.subscriptiondetails.required"
                                                              )
                                                            ) +
                                                            " : " +
                                                            _vm._s(
                                                              fieldDetails.nullable
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "9" } },
                                                    [
                                                      fieldDetails.text_file_config
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-3"
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "customer.page.subscriptiondetails.structuredfile"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "justify-around sm:flex"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "customer.page.subscriptiondetails.length"
                                                                        )
                                                                      ) +
                                                                        ": " +
                                                                        _vm._s(
                                                                          fieldDetails
                                                                            .text_file_config
                                                                            .length
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "customer.page.subscriptiondetails.sequencenumber"
                                                                        )
                                                                      ) +
                                                                        ": " +
                                                                        _vm._s(
                                                                          fieldDetails
                                                                            .text_file_config
                                                                            .sequence_number
                                                                        )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      fieldDetails.validations &&
                                                      fieldDetails.validations
                                                        .length != 0
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "customer.page.subscriptiondetails.validations"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]),
                                                              _c(
                                                                "v-data-table",
                                                                {
                                                                  attrs: {
                                                                    "hide-default-footer":
                                                                      "",
                                                                    headers:
                                                                      _vm.validationHeaders,
                                                                    items:
                                                                      fieldDetails.validations
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }