var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c(
        "v-container",
        { staticClass: "full-height", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "12" } }, [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "shadow sm:rounded-md sm:overflow-hidden" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "text-lg font-medium text-gray-900"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("customer.page.data.title")) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          loading: _vm.getLoadingDistributorSubs,
                          "loading-text": _vm.$t("customer.page.data.progress"),
                          items: Array.from(this.getDistributorSubs.values())
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.data",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "SubscriptionSessions",
                                        params: {
                                          subscription_id: item.subscription_id
                                        }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("customer.page.data.view")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }