import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import dashBoardModule from "../views/Dashboard/Store";
import sessionsModule from "./sessions";
import userModule from "../views/Users/store";
import ApiKeyModule from "../views/Api/Store";
import { isAdmin, isViewer } from "../../../utils/roles.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawerStatus: true,
    currentUser: firebase.auth().currentUser,
    // currentUser: "",
    tenantId: "",
    userClaims: {},
    userDetails: {},
    currentBmmUser: null,
    customerDetails: null
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setDrawerStatus(state, payload) {
      state.drawerStatus = payload;
    },
    setTenantId(state, payload) {
      state.tenantId = payload;
    },
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    setcurrentBmmUser(state, payload) { state.currentBmmUser = payload; },
    setCustomerDetails(state, payload) { state.customerDetails = payload; }
  },
  actions: {
    // authAction({ commit, dispatch, state }) {
    authAction({ dispatch }) {
      dispatch("getTenantID");
    },
    async prepStoreWithAuthValues({ commit, dispatch, state }) {
      firebase.auth().tenantId = state.tenantId;
      const user = firebase.auth().currentUser;
      if (user) {
        commit("setCurrentUser", user);
        const tokenResult = await user.getIdTokenResult();
        commit("setcurrentBmmUser", { id: user.uid, role: tokenResult.claims });
        commit("setDrawerStatus", true);
        dispatch("getUserDetails");
      } else {
        commit("setCurrentUser", null);
        commit("setDrawerStatus", false);
      }
    },
    getUserClaims({ commit }) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(idTokenResult => {
          commit("setUserClaims", idTokenResult.claims);
        });
    },
    // async getTenantID({ commit }) {
    async getTenantID({ commit, dispatch }) {
      const hosturl = window.location.host;
      const tenant = hosturl.split(".")[0];
      /*
       * TODO: Have a safer way of checking and limiting tenant names.
       * Check for for 'admin' early and act accordingly
       * Redirect for 'www' and other reserved or blocked tenant names
       * What character to allow or restrict in tenant names
       */

      const db = firebase.firestore();

      try {
        const snapshot = await db
          .collection("tenants")
          .where("subdomain", "==", tenant)
          .get();

        if (snapshot.empty) {
          commit("setTenantId", "admin");
          return;
        }

        let docs = []
        snapshot.forEach(doc => {
          docs.push(doc);
        });

        const data = docs[0].data();
        const tenant_id = data.tenant_id;
        console.log(`DEBUG. tenant_customer/store. snapshot not empty. Tenant Id found ${tenant_id}`);
        commit("setTenantId", tenant_id);
        dispatch('prepStoreWithAuthValues');
      } catch (err) {
        // TODO: Rediect user to home page when tenant not found
        commit("setTenantId", "admin");
      }


    },

    async getUserDetails({ commit, dispatch, state }) {
      console.log("getting user details");
      // var hosturl = window.location.host;
      // var tenant = hosturl.split(".")[0];
      const user = state.currentUser;
      var db = firebase.firestore();
      try {
        // const insurer_doc = db.collection("Insurers").doc(tenant.toUpperCase());
        const tenant_doc = db.collection("tenants").doc(state.tenantId);
        const users_collection = tenant_doc.collection("users");
        const user_ref = await users_collection.doc(user.uid).get();
        const user_details = user_ref.data();
        const customer_ref = await tenant_doc.collection("customers").doc(user_details.customer_id).get();
        const customer_details = customer_ref.data();
        commit("setUserDetails", user_details);
        commit("setCustomerDetails", customer_details);
        dispatch("getCustomerSubscriptions");
      } catch (err) {
        console.log(err);
      }
    }
  },
  modules: {
    dashBoardModule,
    sessionsModule,
    userModule,
    ApiKeyModule
  },
  getters: {
    getDrawerStatus: state => state.drawerStatus,
    getUserClaims: state => state.userClaims,
    getTenantID: state => state.tenantId,
    getCustomerDetails: state => state.customerDetails,
    isCurrentUserData: (state) => (user) => { return state.currentUser.uid === user.id },
    isAdmin: state => { return state.currentBmmUser ? isAdmin(state.currentBmmUser) : false },
    isViewer: state => { return state.currentBmmUser ? isViewer(state.currentBmmUser) : true }
  }
});
