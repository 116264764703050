var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-4", attrs: { app: "" } },
    [
      _c(
        "v-container",
        { staticClass: "full-height", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "12" } }, [
                _c(
                  "div",
                  { staticClass: "shadow sm:rounded-md sm:overflow-hidden" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
                      },
                      [
                        _c(
                          "h3",
                          { staticClass: "text-lg font-medium text-gray-900" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("customer.page.subscription.title")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("v-spacer")
                      ],
                      1
                    ),
                    _c(
                      "ol",
                      {
                        staticClass:
                          "py-2 space-y-3 list-decimal list-inside bg-white"
                      },
                      _vm._l(_vm.getDistributorSubs.values(), function(
                        sub,
                        subIndex
                      ) {
                        return _c(
                          "li",
                          { key: subIndex },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "SubscriptionDetails",
                                    params: { id: sub.product_id }
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(sub.product_name) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }