var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex justify-center ma-5" }, [
        _vm.isViewer
          ? _c("p", { staticClass: "p-4 bg-white rounded-md" }, [
              _vm._v(" " + _vm._s(_vm.$t("customer.page.upload.error")) + " ")
            ])
          : _c(
              "div",
              { staticClass: "w-2/4 shadow sm:rounded-md sm:overflow-hidden" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex px-4 py-5 bg-white border-b border-gray-200 sm:p-6"
                  },
                  [
                    _c(
                      "h3",
                      { staticClass: "text-lg font-medium text-gray-900" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("customer.page.upload.title")) +
                            " "
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "p-5 space-y-6 bg-white" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.subscriptions,
                        "item-text": _vm.getItemText,
                        label: _vm.$t("customer.page.upload.product")
                      },
                      on: { change: _vm.changeSelection }
                    }),
                    _c("v-select", {
                      attrs: {
                        required: "",
                        items: _vm.event_types,
                        label: _vm.$t("customer.page.upload.event")
                      },
                      model: {
                        value: _vm.event_type,
                        callback: function($$v) {
                          _vm.event_type = $$v
                        },
                        expression: "event_type"
                      }
                    }),
                    _c("v-file-input", {
                      attrs: { label: _vm.$t("customer.page.upload.file") },
                      on: { change: _vm.DataChange }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "px-4 py-3 text-right bg-gray-50 sm:px-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          outlined: "",
                          small: "",
                          disabled: _vm.dialog
                        },
                        on: { click: _vm.Upload }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("customer.page.upload.upload")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "300" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("customer.page.upload.progress")) + " "
                  ),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }